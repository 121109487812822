<div class="modal-header">
  <h5 class="modal-title fw-bold" id="modal-basic-title">
    Search Results
  </h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="row pb-2">
    <div [ngSwitch]="deviceOverview?.deviceCategory">
      <div *ngSwitchCase="'Appliance'">

        <ejs-textbox *ngIf="allowDirectApplianceManagementRead" placeholder="GDID" floatLabelType="Always"
          id="modalApplianceIdRead" value="{{deviceOverview?.id}}" readonly (click)="close()"
          [routerLink]="[directApplianceDetails, deviceOverview?.id]"
          (created)="onLinkCreate($event, textboxApplianceId)" #textboxApplianceId></ejs-textbox>

        <ejs-textbox *ngIf="!allowDirectApplianceManagementRead" placeholder="GDID" floatLabelType="Always"
          id="modalApplianceIdNoRead" value="{{deviceOverview?.id}}" readonly></ejs-textbox>
      </div>
      <div *ngSwitchCase="'Hub'">
        <ejs-textbox *ngIf="allowHubManagementRead" placeholder="GDID" floatLabelType="Always" id="modalHubIdRead"
          value="{{deviceOverview?.id}}" readonly (click)="close()"
          [routerLink]="[hubDetails, deviceOverview?.id]"
          (created)="onLinkCreate($event, textboxHubId)" #textboxHubId></ejs-textbox>

        <ejs-textbox *ngIf="!allowHubManagementRead" placeholder="GDID" floatLabelType="Always" id="modalHubIdNoRead"
          value="{{deviceOverview?.id}}" readonly></ejs-textbox>
      </div>
      <div *ngSwitchCase="'Bluetooth'">
        <ejs-textbox *ngIf="allowAdminApplianceManagementRead" placeholder="GDID" floatLabelType="Always"
          id="modalBluetoothApplianceIdRead" value="{{deviceOverview?.id}}" readonly (click)="close()"
          [routerLink]="[applianceDetails, deviceOverview?.id]"
          (created)="onLinkCreate($event, textboxBtApplianceId)" #textboxBtApplianceId></ejs-textbox>

        <ejs-textbox *ngIf="!allowAdminApplianceManagementRead" placeholder="GDID" floatLabelType="Always"
          id="modalBluetoothApplianceIdNoRead" value="{{deviceOverview?.id}}" readonly></ejs-textbox>
      </div>
    </div>
  </div>
  <div class="row pb-2">
    <ejs-textbox placeholder="Type" floatLabelType="Always" id="modalDeviceType" value="{{deviceOverview?.deviceType}}"
      readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.deviceModel" class="row pb-2">
    <ejs-textbox placeholder="Model" floatLabelType="Always" id="modalDeviceModel"
      value="{{deviceOverview?.deviceModel}}" readonly></ejs-textbox>
  </div>
  <div class="row pb-2">
    <ejs-textbox placeholder="Firmware" floatLabelType="Always" id="modalDeviceFirmwareVersion"
      value="{{deviceOverview?.firmwareVersion}}" readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.seriesIdentifier" class="row pb-2">
    <ejs-textbox placeholder="Series" floatLabelType="Always" id="modalDeviceSeriesIdentifier"
      value="{{deviceOverview?.seriesIdentifier}}" readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.dateAdded" class="row pb-2">
    <ejs-textbox placeholder="Date Added (UTC)" floatLabelType="Always" id="modalDeviceDateAdded"
      value="{{deviceOverview?.dateAdded | dateFormat}}" readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.installationDate" class="row pb-2">
    <ejs-textbox placeholder="Installation Date (UTC)" floatLabelType="Always" id="modalDeviceInstallationDate"
      value="{{deviceOverview?.installationDate | dateFormat}}" readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.releaseGroupName" class="row pb-2">
    <ejs-textbox
      *ngIf="allowDirectApplianceFirmwareReleaseGroupRead || allowHubFirmwareReleaseGroupRead || allowBluetoothApplianceFirmwareReleaseGroupRead"
      placeholder="Release Group Name" floatLabelType="Always" id="modalDeviceReleaseGroupRead"
      value="{{deviceOverview?.releaseGroupName}}" readonly
      (click)="navigateToNewPage(deviceOverview?.releaseGroupId)"
      (created)="onLinkCreate($event, textboxReleasegroup)" #textboxReleasegroup>{{deviceOverview?.releaseGroupName}}></ejs-textbox>

    <ejs-textbox
      *ngIf="!allowDirectApplianceFirmwareReleaseGroupRead && !allowHubFirmwareReleaseGroupRead && !allowBluetoothApplianceFirmwareReleaseGroupRead"
      placeholder="Release Group Name" floatLabelType="Always" id="modalDeviceReleaseGroupNoRead"
      value="{{deviceOverview?.releaseGroupName}}" readonly></ejs-textbox>
  </div>
  <div *ngIf="deviceOverview?.dsmProviderName" class="row pb-2">
    <ejs-textbox placeholder="DSM Provider" floatLabelType="Always" id="modalDSMProvider"
      value="{{deviceOverview?.dsmProviderName}}" readonly></ejs-textbox>
  </div>
</div>
